import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import SaveIcon from "@mui/icons-material/Save";
import { Slide, Snackbar, Stack } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import dayjs from "dayjs";
import { forwardRef, useEffect, useRef, useState } from "react";
import ChangePassword from "../components/authentication/ChangePassword";
import SingleInputField from "../components/common/SingleInputField";
import UserService from "../services/UserService";
import { userInfoSignal } from "../signals/UserInfoSignal";
import Avatar from "../components/avatar";
// import Util from "../utils/Util";
import { BANKS } from "../data/banks";

const keyMap = {
  lastName: "Họ",
  firstName: "Tên",
  email: "Email",
  phoneNumber: "Số điện thoại",
  gender: "Giới tính",
  birthday: "Ngày sinh"
};

const paymentKeyMap = {
  withdrawMethod: "Phương thức rút tiền",
  withdrawAccount: "Tài khoản rút tiền",
  withdrawAccountName: "Chủ tài khoản",
  withdrawBankName: "Ngân hàng"
};

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Transition(props) {
  return <Slide {...props} direction="down" />;
}

function Profile() {
  const [currentPage, setCurrentPage] = useState("profile");
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState("success");
  const [detailStatus, setDetailStatus] = useState("Chỉnh sửa hồ sơ thành công!");

  const [avatarFile, setAvatarFile] = useState(null);
  const avatarInputRef = useRef(null);

  const saveAvatar = async () => {
    try {
      UserService.uploadAvatar({ file: avatarFile });
    } catch (error) {
      console.error(error);
    }
  };

  const removeAvatar = () => {
    avatarInputRef.current.value = null;
    setAvatarFile(null);
  };

  const handleAvatarChange = (event) => {
    setAvatarFile(event.target.files[0]);
  };

  const openAlert = () => {
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") return;
    setOpen(false);
  };

  return (
    <div className="px-4 md:px-0 w-full flex flex-col items-center bg-[#F4F7FE] font-[Inter] mb-20">
      <div className="w-full md:w-[75%] md:min-w-[68.125rem] flex flex-col md:flex-row md:bg-white rounded-xl md:px-[10%] py-12">
        <div className="w-full md:w-[35%] flex flex-col gap-10 bg-white md:bg-none rounded-xl p-[10%] md:p-0">
          <div className="w-full relative flex justify-center md:justify-start">
            {/* <img
              src="/assets/avatar5.png"
              alt="avatar"
              className="w-[9.375rem] h-[9.375rem] absolute -top-20 md:top-auto md:relative md:mt-12 rounded-full border-[5px] border-solid border-white shadow-[0_2px_2px_0_rgba(0,0,0,0.15)]"
              style={{
                background:
                  "url(<path-to-image>), lightgray 50% / cover no-repeat",
              }}
            /> */}
            <div className="relative md:mt-12 group">
              <Avatar
                userInfo={userInfoSignal.value}
                className="!w-[9.375rem] !h-[9.375rem] absolute -top-20 md:top-auto md:relative rounded-full border-[5px] border-solid border-white shadow-[0_2px_2px_0_rgba(0,0,0,0.15)] !text-4xl"
              />
              {avatarFile && (
                <div className="flex flex-col absolute -right-8 top-1/2 -translate-y-1/2  ">
                  <SaveIcon
                    onClick={saveAvatar}
                    sx={{
                      cursor: "pointer"
                    }}
                  />
                  <CancelPresentationIcon
                    onClick={removeAvatar}
                    sx={{
                      cursor: "pointer"
                    }}
                  />
                </div>
              )}
              <label className="w-[9.375rem] h-[9.375rem] bg-gray-300/30 absolute top-0 left-0 rounded-full place-items-center hidden group-hover:grid cursor-pointer">
                <input
                  ref={avatarInputRef}
                  className="hidden"
                  type="file"
                  accept="image/jpeg"
                  onChange={handleAvatarChange}
                />
                <CameraAltIcon
                  sx={{
                    fontSize: "3rem"
                  }}
                />
              </label>
            </div>
          </div>
          <button
            onClick={() => setCurrentPage("profile")}
            className="flex flex-row gap-4 mt-16 md:mt-0">
            <div className=" flex items-center justify-center">
              <img src="/assets/profile-icon.svg" alt="icon" />
            </div>
            <p
              className={`${
                currentPage === "profile" || currentPage === "edit-profile"
                  ? "text-[#2B3674]"
                  : "text-[#A3AED0]"
              } text-base font-medium`}>
              Thông tin cá nhân
            </p>
          </button>
          <button onClick={() => setCurrentPage("change-password")} className="flex flex-row gap-4">
            <div className="w-6 h-6 flex items-center justify-center">
              <img src="/assets/change-password-icon.svg" alt="icon" />
            </div>
            <p
              className={`${
                currentPage === "change-password" ? "text-[#2B3674]" : "text-[#A3AED0]"
              } text-base font-medium`}>
              Đổi mật khẩu
            </p>
          </button>
          <button onClick={() => setCurrentPage("invite-user")} className="flex flex-row gap-4">
            <div className="w-6 h-6 flex items-center justify-center">
              <img src="/assets/icon-share.svg" alt="icon" />
            </div>
            <p
              className={`${
                currentPage === "invite-user" ? "text-[#2B3674]" : "text-[#A3AED0]"
              } text-base font-medium`}>
              Giới thiệu
            </p>
          </button>
        </div>
        <div
          className="w-[0.0625rem] h-[34.9375rem] hidden md:block"
          style={{ background: "rgba(163, 174, 208, 0.50)" }}
        />
        {currentPage === "profile" && <ProfileSection setCurrentPage={setCurrentPage} />}
        {currentPage === "change-password" && (
          <ChangePassword setStatus={setStatus} openAlert={openAlert} />
        )}
        {currentPage === "edit-profile" && (
          <EditProfile
            setCurrentPage={setCurrentPage}
            setStatus={setStatus}
            setDetailStatus={setDetailStatus}
            openAlert={openAlert}
          />
        )}
        {currentPage === "invite-user" && <InviteUser />}
      </div>
      <Stack>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          TransitionComponent={Transition}
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}>
          <Alert onClose={handleClose} severity={status} sx={{ width: "100%" }}>
            {detailStatus}
          </Alert>
        </Snackbar>
      </Stack>
    </div>
  );
}

export default Profile;

function ProfileSection({ setCurrentPage }) {
  // const [copied, setCopied] = useState(false);

  // const copyReferralLink = () => {
  //   const link = `${window.location.origin}/register?referrer=${userInfoSignal.value.email}`;
  //   Util.copyToClipboard(link);
  //   setCopied(true);
  // };

  return (
    <div className="w-full md:w-[65%] flex justify-center bg-white rounded-xl md:bg-none mt-12 md:mt-0 p-[10%] md:p-0">
      <div className="md:mt-12 w-full md:ml-[20%]">
        <div className="relative">
          <p className="text-[#2B3674] text-[1.75rem] font-bold leading-normal">
            {`${userInfoSignal.value?.firstName} ${userInfoSignal.value?.lastName}`}
          </p>
          <p className="text-[#2B3674]/60 text-base font-normal">{userInfoSignal.value?.email}</p>
          <button
            onClick={() => setCurrentPage("edit-profile")}
            className="absolute w-[1.12513rem] h-[1.12513rem] bottom-4 right-4">
            <img src="/assets/edit-profile-icon.svg" alt="edit icon" />
          </button>
        </div>
        <div className="flex flex-col gap-2 md:gap-5 mt-9">
          {Object.keys(keyMap).map((key, index) => (
            <div key={index}>
              <div className="pl-[3%] mb-2">
                <span className="md:hidden text-[#2B3674] text-base font-normal leading-[0.875rem] tracking-[-0.03rem]">
                  {keyMap[key]}:
                </span>
              </div>
              <div
                key={key}
                className="w-full h-14 rounded-xl bg-[#E5F1FF] flex text-[#2B3674] text-base font-normal leading-[0.875rem] tracking-[-0.03rem] px-[10%] md:pl-8 items-center">
                <span className="hidden md:block mr-1">{keyMap[key]}:</span>
                <span>
                  {userInfoSignal.value
                    ? key === "birthday"
                      ? `${
                          userInfoSignal.value[key]
                            ? dayjs(userInfoSignal.value[key]).format("DD/MM/YYYY")
                            : ""
                        }`
                      : userInfoSignal.value[key]
                    : ""}
                </span>
              </div>
            </div>
          ))}
          {/* <div className="flex items-center justify-between gap-4">
            <div className="h-14 rounded-xl bg-[#E5F1FF] flex text-[#2B3674] text-base font-normal tracking-[-0.03rem] px-[10%] md:pl-8 items-center">
              <span>
                Link giới thiệu:{" "}
                {`${window.location.origin}/register?referrer=${userInfoSignal.value.email}`}
              </span>
            </div>
            <button
              className="rounded-lg bg-[#193599] py-2 px-4 w-28 h-14 text-white"
              onClick={copyReferralLink}>
              {copied ? (
                <span className="text-base font-normal leading-[0.875rem] tracking-[-0.03rem]">
                  &#10004;
                </span>
              ) : (
                <span className="text-base font-normal leading-[0.875rem] tracking-[-0.03rem]">
                  Sao chép
                </span>
              )}
            </button>
          </div> */}

          <div className="relative">
            <p className="mt-4 text-lg font-bold">Thông tin thanh toán:</p>
            <button
              onClick={() => setCurrentPage("edit-profile")}
              className="absolute w-[1.12513rem] h-[1.12513rem] bottom-4 right-4">
              <img src="/assets/edit-profile-icon.svg" alt="edit icon" />
            </button>
          </div>
          {Object.keys(paymentKeyMap).map((key, index) => (
            <div key={index}>
              <div className="pl-[3%] mb-2">
                <span className="md:hidden text-[#2B3674] text-base font-normal leading-[0.875rem] tracking-[-0.03rem]">
                  {paymentKeyMap[key]}:
                </span>
              </div>
              <div
                key={key}
                className="w-full h-14 rounded-xl bg-[#E5F1FF] flex text-[#2B3674] text-base font-normal leading-[0.875rem] tracking-[-0.03rem] px-[10%] md:pl-8 items-center">
                <span className="hidden md:block mr-1">{paymentKeyMap[key]}:</span>
                <span>{userInfoSignal.value ? userInfoSignal.value[key] : ""}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

function EditProfile({ setCurrentPage, setStatus, openAlert, setDetailStatus }) {
  const [profileFields, setProfileFields] = useState({});

  useEffect(() => {
    setProfileFields({
      ...userInfoSignal.value,
      gender: userInfoSignal.value.gender || "OTHER",
      withdrawMethod: userInfoSignal.value.withdrawMethod || "MOMO"
    });
  }, [userInfoSignal.value]);

  const editProfileFields = [
    {
      key: "lastName",
      title: "Họ",
      type: "text",
      value: profileFields.lastName,
      onChange: (value) => setProfileFields({ ...profileFields, lastName: value })
    },
    {
      key: "firstName",
      title: "Tên",
      type: "text",
      value: profileFields.firstName,
      onChange: (value) => setProfileFields({ ...profileFields, firstName: value })
    },
    {
      key: "email",
      title: "Email",
      type: "text",
      value: profileFields.email,
      onChange: (value) => setProfileFields({ ...profileFields, email: value })
    },
    {
      key: "phoneNumber",
      title: "Số điện thoại",
      type: "tel",
      value: profileFields.phoneNumber,
      onChange: (value) => setProfileFields({ ...profileFields, phoneNumber: value })
    },
    {
      key: "gender",
      title: "Giới tính",
      type: "select",
      value: profileFields.gender,
      options: [
        {
          label: "Nam",
          value: "MALE"
        },
        {
          label: "Nữ",
          value: "FEMALE"
        },
        {
          label: "Khác",
          value: "OTHER"
        }
      ],
      onChange: (value) => setProfileFields({ ...profileFields, gender: value })
    },
    {
      key: "birthday",
      title: "Ngày sinh",
      type: "date",
      value: profileFields.birthday,
      onChange: (value) => setProfileFields({ ...profileFields, birthday: new Date(value) })
    },
    {
      key: "withdrawMethod",
      title: "Phương thức rút tiền",
      type: "select",
      value: profileFields.withdrawMethod,
      options: [
        {
          label: "Momo",
          value: "MOMO"
        },
        {
          label: "Chuyển khoản ngân hàng",
          value: "BANK_TRANSFER"
        }
      ],
      onChange: (value) => setProfileFields({ ...profileFields, withdrawMethod: value })
    },
    {
      key: "withdrawAccount",
      title: "Tài khoản rút tiền",
      type: "text",
      value: profileFields.withdrawAccount,
      onChange: (value) => setProfileFields({ ...profileFields, withdrawAccount: value })
    },
    {
      key: "withdrawAccountName",
      title: "Chủ tài khoản",
      type: "text",
      value: profileFields.withdrawAccountName,
      onChange: (value) => setProfileFields({ ...profileFields, withdrawAccountName: value })
    },
    {
      key: "withdrawBankName",
      title: "Ngân hàng",
      type: "select",
      value: profileFields.withdrawBankName,
      options: BANKS.map((b) => {
        return {
          label: `${b.shortName} - ${b.name}`,
          value: `${b.shortName} - ${b.name}`
        };
      }),
      onChange: (value) => setProfileFields({ ...profileFields, withdrawBankName: value })
    }
  ];

  function submitSuccess() {
    userInfoSignal.value = profileFields;
  }

  async function onSubmit() {
    try {
      await UserService.updateProfile(profileFields);

      submitSuccess();
      setStatus("success");
      setDetailStatus("Chỉnh sửa hồ sơ thành công!");
      openAlert();
      setCurrentPage("profile");
    } catch (error) {
      setStatus("error");
      setDetailStatus("Chỉnh sửa hồ sơ thất bại!");
      openAlert();
    }
  }

  return (
    <div className="w-full md:w-[65%] relative flex justify-center bg-white rounded-xl md:bg-none mt-12 md:mt-0 p-[5%] md:p-0">
      <button
        onClick={() => setCurrentPage("profile")}
        className="absolute top-6 left-6 flex flex-row items-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="2.5"
          stroke="#2B3674"
          className="w-4 h-4 md:w-6 md:h-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
        </svg>
        <span className="text-[#2B3674] text-sm md:text-base font-semibold">Quay lại</span>
      </button>
      <div className="w-full mt-8 md:mt-12 md:ml-[20%]">
        <p className="w-full text-center text-[#2B3674] text-[1.75rem] font-bold leading-normal">
          Chỉnh sửa hồ sơ
        </p>
        <div className="w-full flex flex-col items-center gap-10 md:gap-5 mt-9">
          {editProfileFields.map((field) => {
            return (
              <>
                {field.key === "withdrawMethod" ? (
                  <p className="mt-4 text-lg font-bold self-start ml-4">Thông tin thanh toán:</p>
                ) : null}
                <SingleInputField
                  id={field.key}
                  key={field.key}
                  title={field.title}
                  type={field.type}
                  value={
                    field.value
                      ? field.key === "birthday"
                        ? dayjs(field.value).format("YYYY-MM-DD")
                        : field.value
                      : ""
                  }
                  label={field.title}
                  required={["firstName", "lastName"].includes(field.key)}
                  readOnly={["email"].includes(field.key)}
                  onChange={field.onChange}
                  showLabel={true}
                  options={field.options}
                />
              </>
            );
          })}
          <button
            onClick={onSubmit}
            className="w-full py-6 md:p-0 md:w-[20.375rem] md:h-[3.75rem] bg-[#3D10EA] rounded-xl md:rounded-lg text-white text-base font-[Inter] font-semibold leading-4 tracking-[-0.03rem]"
            style={{
              background:
                "-webkit-linear-gradient(122deg, rgb(33, 155, 204) -20%,rgba(14, 9, 129, 0.75) 100%)"
            }}>
            Xác nhận
          </button>
        </div>
      </div>
    </div>
  );
}

function InviteUser() {
  const [copied, setCopied] = useState(false);

  const copyReferralLink = () => {
    const link = `${window.location.origin}/register?referrer=${userInfoSignal.value.email}`;
    navigator.clipboard.writeText(link); // Use native clipboard API
    setCopied(true);
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-start relative overflow-hidden">
      {/* Top Section: Banner */}
      <div className="w-full h-96 relative">
        <img
          className="absolute inset-0 w-full h-full object-cover"
          src="/assets/banner-refer-friend.png"
          alt="background"
        />
      </div>

      <div className="bg-white p-10 max-w-4xl w-full flex justify-between items-center relative z-10 -mt-16">
        <div className="p-6">
          <h2 className="text-3xl font-bold text-purple-700 mb-4">Refer A Friend</h2>
          <p className="text-gray-600 mb-6">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna aliqua.
          </p>
          <div className="flex items-center justify-between gap-4">
            <div className="h-14 rounded-xl bg-[#E5F1FF] flex text-[#2B3674] text-base font-normal tracking-[-0.03rem] px-[10%] md:pl-8 items-center">
              <span>
                Link giới thiệu:{" "}
                {`${window.location.origin}/register?referrer=${userInfoSignal.value.email}`}
              </span>
            </div>
            <button
              className="rounded-lg bg-[#193599] py-2 px-4 w-28 h-14 text-white"
              onClick={copyReferralLink}>
              {copied ? (
                <span className="text-base font-normal leading-[0.875rem] tracking-[-0.03rem]">
                  &#10004;
                </span>
              ) : (
                <span className="text-base font-normal leading-[0.875rem] tracking-[-0.03rem]">
                  Sao chép
                </span>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
